import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CKMain from '../views/CKMain.vue'

const routes = [
  {
    path: '/',
    name: 'main',
    component: CKMain,
    meta: {
      title: '杭州土哩土气智能科技有限公司'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Login.vue'),
    meta: {
      title: '登录'
    }
  },

  {
    path: '/manager',
    name: 'manager',
    component: () => import('../views/admin/Admin.vue'),
    meta: {
      title: '此刻清单-管理'
    },
    children: [
      {
        path: '/admin_home',
        name: 'admin_home',
        component: () => import('../components/admin/AdminHome.vue'),
      },
      {
        path: '/admin_user',
        name: 'admin_user',
        component: () => import('../components/admin/AdminUser.vue'),
      },
      {
        path: '/admin_event',
        name: 'admin_event',
        component: () => import('../components/admin/AdminEvent.vue'),
      },
      {
        path: '/admin_feed',
        name: 'admin_feed',
        component:() => import('../components/admin/AdminFeed.vue'),
      },
      {
        path: '/admin_setting',
        name: 'admin_setting',
        component:() => import('../components/admin/AdminOther.vue'),
      }
    ]
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/errorPage/401.vue'),
    hidden: true,
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/errorPage/404.vue'),
    hidden: true,
  },
  // {
  //   path: '/:pathMatch(.*)*', 
  //   component: () => import('@/views/errorPage/404.vue')
  // },


  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export function resetRouter() {
  router.getRoutes().forEach((route) => {
    const { name } = route;
    if (name) {
      router.hasRoute(name) && router.removeRoute(name);
    }
  });
}

export default router
